@import './../../styles/mixins.scss';

.roundTitle {
  > h3 {
    margin-bottom: 1rem;

    > span:first-child {
      position: relative;
      margin-right: 1.5rem;
      font-family: 'CanaroBold';

      &:after {
        position: absolute;
        right: -1rem;
        content: '';
        display: inline-block;
        height: scut-rem(30);
        width: 5px;
        background-color: $primary-color;
        @include medium {
          top: 0.1rem;
        }
        @include large {
          top: 0.2rem;
        }
        @include xlarge {
          top: 0.4rem;
        }
      }
    }
  }

  &__wrapper {
    display: flex;
  }
}

.exercise {
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  overflow: hidden;
  min-width: 180px;
  height: 256px;

  @include medium {
    min-width: 193px;
    height: 274px;
  }

  @include large {
    min-width: 234px;
    height: 333px;
  }

  &__header {
    flex: 3;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
  }

  &__body {
    flex: 2;
    background-color: $gray-dark;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 200px;
    height: 100%;

    &__title {
      flex: 1;
    }

    &__time {
      flex: 0 0 2rem;

      .icon {
        align-self: center;
      }

      span {
        display: inline-block;
        top: -2px;
        left: 0.2rem;
        position: relative;
      }
    }
  }

  & + & {
    margin-left: 1rem;
  }
}
