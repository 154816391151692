@import './../../styles/variables';

.counter {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  height: var(--size);

  &__outer {
    width: 100%;
    height: 100%;
    background: conic-gradient(
      $primary-color calc(var(--ringInner, 360) * 1deg),
      transparent calc(var(--ringOuter, 360) * 1deg)
    );
    border-radius: 100%;
  }

  &__inner {
    position: absolute;
    top: var(--borderwidth);
    left: var(--borderwidth);
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(var(--size) - var(--borderwidth) * 2);
    height: calc(var(--size) - var(--borderwidth) * 2);
    background: $black;
    border-radius: 100%;
    flex-direction: column;
  }

  &__sec {
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: center;
    font-size: var(--fontsize);
    font-family: 'CanaroBold';
    font-variant-numeric: tabular-nums;
    color: white;
  }

  &__text {
    font-size: calc(var(--fontsize) / 2);
    width: 8em;
    height: fit-content;
    position: absolute;
    font-family: 'CanaroBold';
    color: $primary-color;
    top: 0;
    bottom: 0;
    margin: auto 0;
    animation-name: shakeMe;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    &[data-size='lg'] {
      font-size: var(--fontsize);
    }
  }

  &__timeUnit {
    font-family: 'CanaroBook';
    font-size: var(--secsize);
    text-align: center;
  }

  &__sec {
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: center;
    font-family: 'CanaroBold';
    font-variant-numeric: tabular-nums;
    color: white;
    font-size: var(--fontsize);
  }
}

@keyframes shakeMe {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shakeMeLess {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
