@import './styles/globals.scss';
@import './styles/main.scss';

body,
html {
  margin: 0;
  padding: 0;
}

.nextround-home {
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  display: block;
}

.nextround {
  width: 100%;
  min-height: 100vh;
  background-color: $black;
  position: relative;
  overflow: hidden;
  display: block;
  padding: 2rem;

  .component-workouts {
    min-height: 100%;
  }
}

.version-number {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 12px;
  color: #ffffff;
  opacity: 0.7;
}

/* PORTRAIT TABLET VIEW */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .nextround {
    display: none;
  }
}

/*** TABLET STYLING ***/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .nextround {
    .component {
      height: calc(100vh - 4rem);
    }
  }
}
