.please-rotate {
  display: none;
}

/* TABLET PORTRAIT */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .please-rotate {
    padding: 0 2rem;
    background: #000;
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;

    & > div {
      line-height: 0.2;
    }
    svg {
      width: 50%;
      margin-bottom: 2rem;
    }
  }
}
