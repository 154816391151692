@import './../../styles/mixins.scss';

.RoundActive {
  position: relative;
  height: calc(100vh - 4rem);

  &__right {
    display: flex;
    gap: 3rem;
    flex-direction: column;
    align-items: center;
  }

  &__content {
    padding-top: 1rem;
    height: 80%;
  }

  &__stats {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__exercise {
      padding-top: 0;
      display: flex;

      &__box {
        display: flex;
        flex-direction: column;

        > div {
          > h3 {
            color: $gray-light;
            margin-bottom: 0.3rem;
          }

          > h1 {
            font-size: 75px;
            @include medium {
              font-size: 80px;
            }
            @include large {
              font-size: 100px;
            }
          }

          &:first-child {
            margin-bottom: 2rem;
          }
        }
      }

      &__hit {
        text-align: center;
        width: 100%;
      }

      &__image {
        display: block;

        .exerciseImage {
          position: relative;
          display: block;
          background-image: url('../../assets/images/exercise.jpg');
          background-size: cover;
          width: 500px;
          border-radius: 25px;
          padding: 1rem;

          &__stats {
            background-color: rgba($primary-color, 0.5);
            padding: 1rem;
            width: 70%;
            border-radius: 25px;

            span {
              &:first-child {
                display: block;
              }
            }
          }

          .icon {
            position: absolute;
            right: 1rem;
            top: 1rem;
            cursor: pointer;
          }
        }
      }
    }

    &__chart {
      position: absolute;
      bottom: 0;
      margin-top: 1rem;
      height: 230px;
      width: 100%;

      @include medium {
        height: 300px;
      }

      @include large {
        height: 250px;
      }

      @include xlarge {
        height: 400px;
      }
    }
  }
}
