.paginationDots {
  display: flex;
  align-items: center;
  gap: 12px;
}

.singleDot {
  width: 12px;
  height: 12px;
  border-radius: 32px;
  background-color: $gray-light;

  &--active {
    background-color: $white;
  }
}
