@import './../../styles/mixins.scss';

.RoundComplete {
  height: calc(100vh - 4rem);

  > .Header {
    height: 10%;
  }

  &__stats {
    height: 55%;
    > div {
      height: 100%;
    }
  }

  &__footer {
    height: 20%;
    width: 100%;
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: flex-end;

    &__upcoming {
      display: flex;

      span {
        color: $gray-light;
        font-family: 'CanaroBook';
      }
    }

    &__countdown {
      text-align: right;
      position: relative;
      overflow: hidden;

      &--start {
        display: inline-block;
        margin-right: -2rem;
        position: absolute;
        top: 0;
        right: 25rem;
        width: 10rem;
        text-align: left;
      }
    }
  }

  &__chart {
    display: flex;
    position: relative;
    width: 100% !important;
    height: 100%;
  }
}
