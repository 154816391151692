.groupWorkoutForm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  background-color: #1a1a1a; /* Form background */
  color: #fff;
  min-height: 100vh;
  width: 100%;

  &__form {
    background: #f9f9f9; /* White form background */
    border-radius: 8px;
    padding: 20px;
    max-width: 800px; /* Fixed content width */
    width: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Slight shadow for depth */
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: #333; /* Neutral text color inside form */
  }

  label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #000; /* Ensure labels are black for readability */
    display: block; /* Stack label and input vertically */
  }

  input[type="text"],
  input[type="number"],
  textarea,
  input[type="file"] {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #fff; /* White input background */
    margin-bottom: 12px; /* Add spacing between inputs */
  }

  textarea {
    resize: none;
    height: 120px;
  }

  .inline-group {
    display: flex;
    align-items: center;
    gap: 20px; /* Space between items */
    margin-bottom: 12px;

    label {
      display: flex;
      align-items: center;
      gap: 8px; /* Space between label text and input */
      margin-bottom: 0; /* Reset margin for inline fields */
    }

    input[type="number"] {
      width: 60px; /* Restrict number input width for better alignment */
    }
  }

  button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff; /* White text on buttons */
    background: #007bff; /* Blue button background */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-top: 10px; /* Add spacing from content above */

    &:hover {
      background: #0056b3; /* Darker blue on hover */
    }

    &:disabled {
      background: #cccccc; /* Gray background for disabled state */
      cursor: not-allowed;
    }
  }

  .delete-button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 14px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #ff1a1a;
    }
  }

  &__actions {
    margin-top: 20px;
    text-align: center;
    display: flex;
    gap: 10px; /* Add spacing between buttons */
    justify-content: center; /* Center align action buttons */
  }
}

audio {
  width: 100%;
}

.groupWorkoutForm__audio {
  width: 100%;
  margin-top: 20px; /* Add some spacing above the audio player */
}

.groupWorkoutForm__form input:disabled,
.groupWorkoutForm__form button:disabled,
.groupWorkoutForm__form textarea:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
