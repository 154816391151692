@import './../../styles/mixins.scss';

.challenges {
  margin-top: 1.5rem;

  &__wrapper {
    display: flex;
  }

  &__header {
    display: flex;
    margin-bottom: 0.5rem;

    > h3:first-child {
      padding-right: 0.5rem;
      position: relative;

      &:after {
        content: '';
        height: 75%;
        width: 5px;
        position: absolute;
        right: -3px;
        top: 4px;
        background-color: $primary-color;
      }
    }

    > h3:last-child {
      padding-left: 0.5rem;
    }
  }
}

.challenge-card {
  margin: 0 1rem 0 0;
  width: 35rem;
  max-width: 100%;

  &__wrapper {
    border-radius: 32px;
    background-color: $gray-dark;
    display: flex;
    flex-direction: column;
  }

  &__title {
    position: relative;
    background-color: $primary-color;
    border-radius: 32px;
    padding: 1rem;
    flex: 2;

    > .icon {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
  }

  &__content {
    padding: 1rem;
    display: flex;
    flex: 4;

    > .icon {
      flex: 2;
      margin-right: 0.5rem;
    }

    &__time {
      display: flex;

      > .icon {
        margin-right: 0.5rem;
      }
    }

    &__text {
      flex: 4;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      > h6:last-child {
        display: flex;
        align-items: center;

        & .icon {
          margin-right: 0.5rem;
        }
      }
    }
  }
}
