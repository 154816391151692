@import './mixins.scss';
@import './scut.scss';

@font-face {
  font-family: 'CanaroBold';
  // src: url('..fonts/canaro/canaro-bold.eot'); /* IE9*/
  src: url('../assets/fonts/canaro/canaro-bold.eot'); /* IE9*/
  src: url('../assets/fonts/canaro/canaro-bold.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/canaro/canaro-bold.woff2')
      format('woff2'),
    /* chrome、firefox */ url('../assets/fonts/canaro/canaro-bold.woff')
      format('woff'),
    /* chrome、firefox */ url('../assets/fonts/canaro/canaro-bold.ttf')
      format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('../assets/fonts/canaro/canaro-bold.svg#Canaro W00 Bold')
      format('svg'); /* iOS 4.1- */
}
@font-face {
  font-family: 'CanaroBook';
  src: url('../assets/fonts/canaro/CanaroBook.eot'); /* IE9*/
  src: url('../assets/fonts/canaro/CanaroBook.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/canaro/CanaroBook.woff2') format('woff2'),
    /* chrome、firefox */ url('../assets/fonts/canaro/CanaroBook.woff')
      format('woff'),
    /* chrome、firefox */ url('../assets/fonts/canaro/CanaroBook.ttf')
      format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('../assets/fonts/canaro/CanaroBook.svg#Canaro W00 Book') format('svg'); /* iOS 4.1- */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: 'CanaroBold';
  font-weight: normal;
  &.light {
    font-family: 'CanaroBook';
  }
  &.gray {
    color: $gray-light;
  }
}

h1 {
  @include text(scut-rem(40), 47px, null, null);

  @include medium {
    @include text(scut-rem(43), 50px, null, null);
  }

  @include large {
    @include text(scut-rem(53), 62px, null, null);
  }

  @include xlarge {
    @include text(scut-rem(60), 70px, null, null);
  }
}

h2 {
  @include text(scut-rem(27), 33px, null, null);

  @include medium {
    @include text(scut-rem(29), 36px, null, null);
  }

  @include large {
    @include text(scut-rem(36), 45px, null, null);
  }

  @include xlarge {
    @include text(scut-rem(40), 50px, null, null);
  }
}

h3 {
  @include text(scut-rem(21), 29px, null, null);

  @include medium {
    @include text(scut-rem(23), 31px, null, null);
  }

  @include large {
    @include text(scut-rem(28), 39px, null, null);
  }

  @include xlarge {
    @include text(scut-rem(32), 44px, null, null);
  }
}

h4 {
  @include text(scut-rem(17), 24px, null, null);

  @include medium {
    @include text(scut-rem(19), 26px, null, null);
  }

  @include large {
    @include text(scut-rem(23), 32px, null, null);
  }

  @include xlarge {
    @include text(scut-rem(26), 36px, null, null);
  }
}

h5 {
  @include text(scut-rem(13), 16px, null, null);

  @include medium {
    @include text(scut-rem(14), 17px, null, null);
  }

  @include large {
    @include text(scut-rem(18), 21px, null, null);
  }

  @include xlarge {
    @include text(scut-rem(20), 24px, null, null);
  }
}

p {
  @include text(scut-rem(10), 15px, null, null);

  @include medium {
    @include text(scut-rem(11), 16px, null, null);
  }

  @include large {
    @include text(scut-rem(14), 20px, null, null);
  }

  @include xlarge {
    @include text(scut-rem(16), 22px, null, null);
  }

  &.small {
    @include text(scut-rem(9), 12px, null, null);

    @include medium {
      @include text(scut-rem(10), 13px, null, null);
    }

    @include large {
      @include text(scut-rem(12), 16px, null, null);
    }

    @include xlarge {
      @include text(scut-rem(14), 18px, null, null);
    }
  }
}
