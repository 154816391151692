.circular-timer {
  position: relative;

  .svg-circle {
    svg{
      transform: rotate(-90deg);
    }
  }

  .circle-bg {
    fill: none;
    stroke: #2c2c2c;
  }

  .circle-progress {
    fill: none;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.3s;
  }

  .timer-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    overflow: visible; 

    h1 {
      font-weight: bold;
      color: #fff;
    }

    .switch-info {
      margin-top: 10px;
      font-size: 0.8em;
      color: #ccc;

      .progress-bar-svg {
        display: block;
        margin: 5px auto;
      }

      .current-round {
        font-weight: bold;
        color: #ffbd2c;
      }
    }
  }

  &.fullscreen {
    width: 100vw;
    height: 100vh;

    .timer-content h1 {
      font-size: 5rem;
    }
  }
}
