.workout-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem;
  color: black;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    gap: 2rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(300px, 1fr));
  }
}

.workout-tile {
  position: relative;
  border-radius: 0.6rem;
  background-color: #eee;
  .img-container {
    background: #eee no-repeat 50% 50% / fill;
    overflow: hidden;
    border-radius: 0.6rem;
    height: 50%;
    min-height: 180px;
  }

  .img-container > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .workout-info {
    border-radius: 0.6rem;
    padding: 1rem;
    min-height: 180px;
    background-color: #fcf4f4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 50%;
  }

  .left {
    background-color: #fcf4f4;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    padding: 0 1rem;
    font-size: 1.5rem;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }

  .right {
    display: flex;
    background-color: #fcf4f4;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    padding: 0 1rem;
    font-size: 1.5rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.workouts-body {
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.workouts-body::-webkit-scrollbar {
  display: none;
}

.center-text {
  text-align: center;
}

.group-workout-home {
  button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff; /* White text on buttons */
    background: #007bff; /* Blue button background */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-top: 10px; /* Add spacing from content above */

    &:hover {
      background: #0056b3; /* Darker blue on hover */
    }

    &:disabled {
      background: #cccccc; /* Gray background for disabled state */
      cursor: not-allowed;
    }
  }
}
