.popupLogin {
  & > h2 {
    text-align: center;
    margin-bottom: $space-1;
  }

  &__noAccount {
    justify-content: center;
    margin-bottom: $space-2;
    display: flex;
    gap: 0.5rem;

    & > button {
      color: $primary-color;
      text-decoration: underline;
    }
  }

  & > input {
    width: 100%;
  }

  & > .checkBox {
    margin: $space-1 0;
  }

  &__error {
    color: $secondary-color;
    margin: 0 0 0 3rem;
  }

  &__btnsWrapper {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > .Button--text {
      color: $gray-light;
      text-decoration: underline;
    }
  }
}
