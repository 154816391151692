.statsWorkoutComplete {
  height: 100%;

  &__box {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    grid-template-areas:
      'avg low statsLow'
      'statsAvg high statsHigh';

    > .low {
      grid-area: low;
    }
    > .statsLow {
      grid-area: statsLow;
    }
    > .high {
      grid-area: high;
    }
    > .statsHigh {
      grid-area: statsHigh;
    }
    > .avg {
      grid-area: avg;
    }
    > .statsAvg {
      grid-area: statsAvg;
    }

    &:not(:first-child) {
      border-left: 4px solid #001f19;
    }
  }
}
