.saveResults {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > button {
    margin-top: auto;
    width: 100%;
    padding: 0.5rem;
  }
}
