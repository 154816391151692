@import './../../styles/mixins.scss';

.header {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;

  &__title {
    text-align: center;
  }

  &__tools {
    justify-self: end;
    text-align: right;
    display: flex;
    gap: $space-half;
    align-items: center;

    & > .icon {
      cursor: pointer;
    }

    & > div:first-child {
      margin-right: 10px;
    }
  }
}
