.track-list {
    display: flex;
    overflow-x: scroll;
    padding: 20px;
    gap: 10px; /* add gap to ensure spacing between track items */
    margin-right: 20px;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .track-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 48px;
    border-radius: 30px;
    background-color: #f3c1be;
    padding: 10px;
    flex-shrink: 0;
    transition: background-color 0.3s;
  
    &.active {
      background-color: #e69e9a;
    }
  
    .track-arrow {
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #e69e9a;
    }
  
    .track-title {
      font-size: 0.8rem;
      font-weight: 900;
      color: black;
      text-align: center;
    }
  }