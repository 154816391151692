.saveResultsPopup {
  text-align: center;
  margin-bottom: $space-1;

  &__instructions {
    &__noAccount {
      justify-content: center;
      margin-bottom: $space-2;
      display: flex;
      gap: 0.5rem;

      & > button {
        color: $primary-color;
        text-decoration: underline;
      }
    }

    &__noPhone {
      margin-top: $space-2;
      display: flex;
      gap: 0.5rem;

      & > button {
        text-decoration: underline;
      }
    }

    &__cardsWrapper {
      display: grid;
      gap: $space-2;
      grid-template-columns: repeat(2, 1fr);
    }

    &__card {
      display: grid;
      justify-items: center;
      gap: $space-2;
      padding: $space-2;
      background-color: $gray-dark;
      border-radius: 20px;

      & > img {
        height: 150px;
      }

      &__content {
        display: flex;
        gap: $space-1;
      }
    }
  }

  &__form {
    &__card {
      margin-top: $space-2;
      padding: $space-3 $space-5;
      background-color: $gray-dark;
      border-radius: 20px;

      & > input {
        width: 100%;
      }
    }

    &__buttons {
      margin-top: $space-2;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > .Button--text {
        color: $gray-light;
        text-decoration: underline;
      }
    }

    & > .Button {
      margin-top: $space-2;
    }
  }
}
