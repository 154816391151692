.popupForgotPw {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h2 {
    margin-bottom: $space-1;
  }

  & > h4 {
    margin-bottom: $space-4;
  }

  & > input {
    margin-bottom: $space-3;
  }
}
