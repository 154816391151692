@import './../../styles/mixins.scss';

.intensity {
  &__wrapper {
    display: flex;
    gap: $space-2;

    > h3 {
      color: $gray-light;
    }

    &--column {
      flex-direction: column;
    }
  }

  &__row {
    min-height: 75px;
    display: flex;
    align-items: flex-end;
    gap: $space-1;
  }
}

.singleIntensity {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $space-1;
  }

  &__number {
    @include text(scut-rem(30), 0.75, 400, null);
    transition: font-size 0.3s;

    &--active {
      @include text(scut-rem(56), 0.75, 700, null);
    }
  }

  &__color {
    height: 13px;
    width: 45px;
    border-radius: 8px;
    background-color: var(--background-color);

    &--empty {
      border: 2px solid $gray-light;
    }
  }
}
