.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  width: 200px; 
  height: 200px;
  border-radius: 50%;
  background: conic-gradient(#feca57, #feca57, #feca57, #00c9ff, #feca57);
  mask: radial-gradient(closest-side, transparent 82%, black 61%); /* Masks the inner part */
  -webkit-mask: radial-gradient(closest-side, transparent 82%, black 61%); /* For Webkit browsers */
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
