@import '../../styles/variables';

.workoutCard {
  color: white;
  flex: 0 0 20rem;
  margin-bottom: 2rem;

  &.small {
    flex: 0 0 262px;
  }

  a {
    color: white;

    &:active {
      color: white;
    }
  }

  &__container {
    width: 18rem;
    margin: 0 auto;
    background-color: $gray-dark;
    border-radius: 10px 10px 32px 32px;
    display: inline-block;
    overflow: hidden;
  }

  &__header {
    height: 15rem;
    display: block;
    background-size: cover;
    position: relative;
    background-color: black;
  }

  &__content {
    padding: 1rem;

    > div {
      margin: 0.5rem;
    }

    &--freestyle {
      text-align: center;

      .workout__content__title {
        font-size: 1.5rem;
      }
    }

    &__title {
      font-weight: bold;
    }

    &__duration {
      display: flex;

      .icon {
        margin-right: 0.5rem;
      }
    }
  }
}
