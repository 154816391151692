.horizontal-tabs {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;

  &__header {
    display: flex;
    flex-wrap: wrap; /* Allows tabs to wrap to the next row */
    white-space: nowrap; /* Prevents individual tab content from wrapping */
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    gap: 10px; /* Adds spacing between rows and tabs */
  }

  &__tabs-container {
    display: flex;
    flex-wrap: wrap; /* Enable wrapping of tabs to a new row */
    gap: 10px;
    padding: 0 10px;
    max-width: 100%; /* Keep tabs within parent container */
  }

  &__tab {
    flex-shrink: 0; /* Prevent tabs from shrinking */
    padding: 10px 20px;
    background-color: #ffffff !important; /* Force white background for inactive tabs */
    border: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    color: #333 !important; /* Force dark text color for inactive tabs */

    &:hover {
      background-color: #f2f2f2 !important; /* Light gray hover effect */
    }

    &.active {
      background-color: #007bff !important; /* Blue background for active tab */
      color: #ffffff !important; /* White text for active tab */
      border-color: #0056b3 !important;
      border-bottom: 2px solid #ffffff !important; /* Seamless transition */
    }
  }

  &__add-tab {
    flex-shrink: 0; /* Prevent shrinking of the Add Track button */
    padding: 10px 20px;
    background-color: #28a745 !important; /* Force green background */
    color: #ffffff !important; /* Force white text */
    border: 1px solid #1e7e34;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;

    &:hover {
      background-color: #218838 !important; /* Darker green hover effect */
    }
  }

  &__content {
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 0 0 4px 4px;
    max-width: 800px; /* Limit content width */
    margin: 0 auto; /* Center the content */
  }
}
