@import '../../styles/variables';

.RoundsOverview {
  display: flex;
  flex-direction: column;

  &__roundsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    &__round {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-right: 1.5rem;

      &:last-child {
        margin-right: 0;
      }

      &__title {
        flex: 1;
        font-family: 'CanaroBook';
        text-align: center;
        height: 42px;
        line-height: 42px;
        margin-bottom: 0.5rem;

        &.active {
          font-family: 'CanaroBold';
        }
      }

      &__names {
        display: flex;

        & > div {
          flex: 1;
          text-align: center;
          font-size: 20px;
          font-family: 'CanaroBook';
          color: $gray-light;

          &.active {
            font-family: 'CanaroBold';

            &.player1 {
              color: $player1;
            }

            &.player2 {
              color: $player2;
            }
          }
        }
      }

      &__bar {
        border-radius: 8px;
        height: 32px;
        position: relative;
        display: flex;

        &.dualplayer {
          & > div {
            &:first-child {
              border-radius: 10px 0 0 10px;
            }

            &:nth-child(2) {
              border-radius: 0 10px 10px 0;
            }
          }
        }

        &__fill {
          border-radius: 10px;
          border: 1px solid $player1;
          flex: 1;

          &.active {
            background-color: $player1Inactive;
          }

          &.done {
            background-color: $player1;
          }
        }

        &__second {
          border-radius: 10px;
          border: 1px solid $player2;
          flex: 1;

          &.active {
            background-color: $player2Inactive;
          }

          &.done {
            background-color: $player2;
          }
        }
      }
    }
  }
}
