@import '../../../styles/mixins.scss';

.popupFreestyle {
  &__buttons {
    display: grid;
    grid-template-areas: 'left center right';
    grid-template-columns: repeat(3, 1fr);
    margin-top: 2rem;

    & > .paginationDots {
      grid-area: center;
      margin-inline: auto;
    }

    & > .Button > h3 {
      position: relative;
      width: fit-content;
      margin-inline: auto;

      & > svg {
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
      }
    }

    &--left {
      grid-area: left;

      & > h3 > svg {
        left: -2rem;
      }
    }

    &--right {
      grid-area: right;

      & > h3 > svg {
        right: -2rem;
      }
    }
  }
}

.setupFreestyleRounds {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > h2 {
    text-align: center;
    margin-bottom: 2rem;
  }

  &__setting {
    display: flex;
    align-items: center;
    gap: 2rem;

    &__buttons {
      display: flex;
      border-radius: 16px;
      gap: 1rem;
      align-items: center;
      padding: 0.5rem;
      background-color: $gray-dark;

      & > h4 {
        margin-inline: 1.5rem;
        text-align: center;
        width: 2ch;
      }

      & > .Button {
        width: 45px;
        height: 45px;
      }

      & > .Button {
        border-color: $white;

        &:first-of-type:hover {
          background-color: transparent;
        }

        &:last-of-type {
          background-color: $white;
        }
      }
    }
  }
}

.setupFreestyleIntensity {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > h2 {
    text-align: center;
    margin-bottom: 1rem;
  }

  & > h4 {
    text-align: center;
  }

  &__weightButtons {
    padding: 3.5rem;
    background-color: $gray-dark;
    border-radius: 32px;
  }
}
