@import './../../../styles//variables.scss';

@mixin connectionBorder {
  $colors: (
    'red': $secondary-color,
    'yellow': $tertiary-color,
    'green': $primary-color,
  );

  @each $color-name, $color-value in $colors {
    &[data-color='#{$color-name}'] {
      border: 1px solid $color-value;
    }
  }
}

.connectionLabel {
  display: flex;
  padding: 0.625rem 1.25rem 0.625rem 0.625rem;
  align-items: center;
  gap: 0.625rem;
  margin-bottom: $space-2;
  @include connectionBorder;
}

.connectionInfo {
  display: flex;
  padding: 3rem 2.5rem;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: $space-3;
  width: 100%;
  @include connectionBorder;
}

.connectionPopup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > h5 {
    margin-bottom: $space-half;
  }

  & > h4 {
    width: 70%;
    margin-bottom: $space-2;
  }

  & a {
    font-family: 'CanaroBold';
    text-decoration: none;
    color: white;
  }
}
