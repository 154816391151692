.popupAccountCreated {
  & > * {
    text-align: center;
  }

  & > h2.light {
    margin-bottom: $space-2;
  }

  &__btnsWrapper {
    margin-top: $space-2;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
