@import '../../styles/mixins.scss';

.challenge-results {
  height: 100%;
  background-color: $gray-dark;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__challenges {
    display: flex;
    justify-content: space-between;
    margin: 0 0 0.5rem 0;

    &__key {
      color: $gray-light;
    }
  }

  &__constraint {
    border-top: 4px solid #001f19;
    padding: 1rem 0.5rem;
    display: flex;

    > .icon {
      margin-right: 1rem;
    }

    &--failed {
      font-family: 'CanaroBook';
      color: $gray-light;
    }
  }
}
