.controls {
    background-color: transparent;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 100px;
  }
  
  .button {
    background-color: #ffffff;
    color: #ff0000;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
  }
  
  .button.main {
    background-color: #ff0000;
    color: #ffffff;
  }
  
  .button:hover {
    background-color: #ffcccc;
  }
  
  .button.main:hover {
    background-color: #cc0000;
  }
  
  .button svg {
    width: 24px;
    height: 24px;
  }