@import './../../../styles/mixins.scss';

.PopupLogout {
  text-align: center;

  > h3 {
    margin: 5rem 0 3rem;
  }

  &__buttonBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
    margin-bottom: 1rem;

    @include medium {
      flex-direction: row;
    }
  }
}
