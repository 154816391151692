@import './../../styles/mixins.scss';

.component {
  height: calc(100vh - 4rem);

  > .Header {
    height: 10%;
  }
}

.freestyleQuickstartActive {
  height: calc(100vh - 4rem);
  display: flex;
  flex-direction: column;
  flex-grow: 0.5;

  &__chart {
    height: 50%;
    padding: 1rem;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }
}
