.group-workout-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.group-workout-setup {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.setup-instructions {
  background-color: #f9f9f9;
  color: #555555;
  padding: 15px 20px;
  border-radius: 8px;
  margin-bottom: 30px;

  p {
    margin-bottom: 10px;
    font-size: 16px;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;

    li {
      margin-bottom: 8px;
      font-size: 14px;
    }
  }
}

.start-workout-button {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #ff4d4d;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff1a1a;
  }
}

.loading,
.error-message {
  text-align: center;
  font-size: 16px;
  color: #555555;
  margin-top: 40px;
}

@media (max-width: 768px) {
  .group-workout-title {
    font-size: 20px;
  }

  .start-workout-button {
    font-size: 14px;
    padding: 10px 16px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .group-workout-title {
    font-size: 22px;
  }

  .start-workout-button {
    font-size: 15px;
    padding: 11px 18px;
  }
}

@media (min-width: 1201px) {
  .group-workout-title {
    font-size: 24px;
  }

  .start-workout-button {
    font-size: 16px;
    padding: 12px 20px;
  }
}

.bag-select-checkbox {
  color: black;
}
