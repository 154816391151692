.loginBtn {
  > button {
    display: flex !important;
    flex-direction: row;
    background-color: transparent;
    border: none;
    align-items: center;

    > h3 {
      padding-right: 10px;
    }
  }
}
