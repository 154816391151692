.headerActive {
  &__progress {
    justify-content: flex-end;
    flex-direction: column;
    display: flex;
  }

  &__counterValue {
    text-align: center;
  }

  &__tools {
    display: flex;
    justify-content: flex-end;

    > button:first-child {
      flex-shrink: 0;
      margin-right: 3.5rem;
      height: 4rem;
      width: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > button:last-child {
      width: 13rem;
    }
  }
}
