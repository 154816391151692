.connectionIndicator {
  position: relative;
  display: flex;
  align-items: center;

  &__data {
    align-self: center;
    padding-right: 0.5rem;
    // position: absolute;
    // top: 50%;
    // transform: translateY(-50%);
    // right: 130%;

    & > p {
      white-space: nowrap;
      margin: 0;
      font-size: 0.75rem;
      line-height: 100%;
    }
  }
}
