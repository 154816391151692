.popupRegister {
  & > h2 {
    margin-top: $space-4;
  }

  & > h5 {
    margin-top: $space-1;
  }

  & > .input {
    width: 100%;
    margin-top: $space-2;
  }

  &__btnsWrapper {
    margin-top: $space-2;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
