.PopupQuitWorkout {
  text-align: center;

  > h2 {
    margin-bottom: 2rem;
  }

  > h3 {
    margin: 3rem;
    margin-bottom: 6rem;
  }

  .Button {
    margin: 0 2rem;
  }
}
