@import './../../styles/mixins.scss';

.challenge-feedback {
  width: 100%;
  height: 80%;
  background-color: $gray-dark;
  border-radius: 32px;
  padding: 1.5rem;
  display: grid;
  grid-template-columns: 33% 66%;
  grid-template-rows: 30% 30% 40%;
  grid-template-areas:
    'title title'
    'message message'
    'description goal';

  &__title {
    grid-area: title;
    display: flex;
    align-items: center;

    > .icon {
      margin-right: 1rem;
    }
  }

  &__message {
    grid-area: message;

    &--success,
    &--failed {
      @include text(scut-rem(37.5), 45px, null, null);
      font-family: 'CanaroBook';

      @include medium {
        @include text(scut-rem(40), 48px, null, null);
      }

      @include large {
        @include text(scut-rem(50), 60px, null, null);
      }
    }

    &--success {
      color: $primary-color;
    }

    &--failed {
      color: $secondary-color;
    }
  }

  > h3 {
    grid-area: description;
    color: $gray-light;
  }

  &__goal {
    grid-area: goal;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: 'CanaroBold';

    > .icon {
      margin-right: 1rem;
    }

    &--primary {
      color: $primary-color;
    }

    &--secondary {
      color: $secondary-color;
    }

    > h1 {
      @include text(scut-rem(64), 76px, null, null);

      @include medium {
        @include text(scut-rem(68), 92px, null, null);
      }

      @include large {
        @include text(scut-rem(84.8), 101.6px, null, null);
      }
    }
  }
}
