@import '../../styles/mixins.scss';

.WorkoutComplete {
  height: calc(100vh - 4rem);

  > .Header {
    height: 10%;
  }

  &__topRow {
    height: 10%;
  }

  &__workout-info {
    height: 80%;

    &__details {
      height: 100%;

      &__bigStats {
        height: 10%;
        display: flex;
        flex-direction: row !important;
        justify-content: space-between;
        align-items: center;

        > div {
          width: 50%;
          display: flex;
          justify-content: center;

          &:not(:first-child) {
            border-left: 4px solid #001f19;
          }

          > h3:first-child {
            margin-right: 2rem;
          }
        }
      }

      &__graph {
        height: 70%;

        > div {
          height: 100%;
        }
      }

      &__stats {
        height: 20%;
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;

    > h5 {
      margin-right: 0.5rem;
    }
  }

  &__dualplayer {
    display: flex;
  }

  &__head {
    padding-top: 1rem;
  }

  .save {
    justify-content: center;
    display: flex;
    flex-direction: column;

    p {
      text-align: center;
      margin: 0.5rem;
    }

    Button {
      margin: 0;
    }
  }

  &__exit {
    text-align: right;

    > button {
      width: 13rem;
    }
  }

  &__workout-exercises {
    display: flex;
    margin-top: 2rem;
  }

  &__workout-details {
    display: flex;
    padding: 0.5rem 0 1rem;
    align-items: center;

    > h4 {
      > span {
        margin-left: 0.5rem;
        padding-left: 0.5rem;
        border-left: 2px solid $primary-color;

        &:nth-child(2) {
          border-left: none;
          margin-left: 0;
        }
      }
    }
  }
  &__hitthebag {
    display: flex;
    margin-left: 1rem;
    font-size: calc(var(--fontsize) / 1.5);
    width: 12em;
    height: fit-content;
    font-family: 'CanaroBold';
    color: $primary-color;
    animation-name: shakeMeLess;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &__back {
    cursor: pointer;

    a {
      font-family: 'CanaroBold';
      text-decoration: none;
      color: white;
    }
  }

  .middle-row {
    padding-left: 1rem;
    padding-right: 0;
  }

  .box {
    background-color: $gray-dark;
    border-radius: 2rem;
    padding: 0.75rem 1.25rem;
    display: flex;
    flex-direction: column;
    width: 100%;

    h3 {
      margin-bottom: 1rem;
    }

    &__content {
      flex: 1;
      border-top: 4px solid #001f19;
      margin-top: 0.5rem;
      padding: 1rem 0.5rem;

      &--row {
        border-top: 0;
        border-left: 4px solid #001f19;
        padding: 0 1rem;
        margin: 0;

        & > .row {
          & > div {
            height: 2rem;
          }
        }
      }

      p {
        strong {
          color: $primary-color;
        }
      }

      .primary {
        color: $primary-color;
        font-family: 'CanaroBold';
      }
    }

    &.center {
      text-align: center;
    }

    h3 {
      margin: 0;
    }

    .title {
      color: $gray-light;
      text-align: left;
    }

    .value {
      color: white;
      font-family: 'CanaroBold';
    }
  }
}

.sidebar {
  display: flex;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    canvas {
      padding: 1rem;
      background-color: #fff;
    }

    ul {
      list-style-type: circle;
      li {
        font-size: 11pt;
      }
    }
  }
}

.login {
}
