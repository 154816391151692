@import '../../styles/variables';

.apiKeyPage {
  padding: 1rem;
  text-align: center;
}
input {
  flex: 1;
  width: 80%;
  background-color: $gray-light;
  color: black;

  line-height: 40px;
  border: 0;
  font-size: 32px;
  font-family: 'CanaroBold';
  margin: 1rem 0;
}
