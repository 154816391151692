/* GroupWorkoutNewWorkout.css */

.preworkout-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.round-container {
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-wrapper {
  position: relative;
  width: 100%;
}

.fullscreen-video {
  width: 100%;
}

@media (max-width: 768px) {
  .fullscreen-video {
    height: 60vh;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .fullscreen-video {
    height: 70vh;
  }
}

@media (min-width: 1201px) {
  .fullscreen-video {
    height: 80vh;
  }
}

.start-pre-workout-button {
  margin-top: 10px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 4px;
}

.start-pre-workout-button:hover {
  background-color: #ff1a1a;
}
