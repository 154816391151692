@import './../../styles/mixins.scss';

.home {
  background-image: url('../../assets/images/background-home.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-position: center;
  justify-content: space-between;

  &__header {
    display: flex;
    flex-direction: row;
    margin: 2rem;
    flex: 1;
    justify-content: space-between;

    &__btns {
      align-self: flex-start;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }

    &__logo {
      background-image: url('../../assets/images/logo_big.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 285px;
      @include medium {
        width: 315px;
      }

      @include large {
        width: 375px;
      }

      @include xlarge {
        width: 425px;
      }
    }
  }

  &__cta {
    user-select: none;
    flex: 1;
    background-color: rgba($primary-color, 0.5);
    width: calc(100% - 5rem);
    padding: 1rem 0;
    margin: 0 auto;
    text-align: center;
    border-radius: 1rem;

    &__subTitle {
      margin-top: 1rem;
      font-family: 'CanaroBold';
      @include text(scut-rem(25), 31px, null, null);

      @include medium {
        @include text(scut-rem(27), 33px, null, null);
      }

      @include large {
        @include text(scut-rem(34), 40px, null, null);
      }

      @include xlarge {
        @include text(scut-rem(38), 46px, null, null);
      }
    }
  }

  &__button {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    > a > .Button {
      width: 285px;
      @include medium {
        width: 315px;
      }

      @include large {
        width: 375px;
      }

      @include xlarge {
        width: 425px;
      }
    }

    &__subTitle {
      margin-bottom: 1rem;
      @include text(scut-rem(25), 31px, null, null);

      @include medium {
        @include text(scut-rem(27), 33px, null, null);
      }

      @include large {
        @include text(scut-rem(34), 40px, null, null);
      }

      @include xlarge {
        @include text(scut-rem(38), 46px, null, null);
      }
    }
  }
}
