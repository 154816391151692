@import './../../styles/mixins.scss';
@import '../../styles/variables';

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: $black;
  height: 100vh;
  width: 100vw;

  > * {
    max-width: 50ch;
  }

  h1 {
    margin-bottom: 1rem;
  }

  p {
    margin: 0;
    font-size: 1rem;
    line-height: 33px;
  }

  &__btn-box {
    display: flex;
    margin-top: 4rem;
    flex-direction: column;

    @include medium {
      flex-direction: row;
    }

    > button {
      margin: 0 1rem 1rem;
      font-size: 1.5rem;
    }

    :last-child {
      border: 3px solid $secondary-color;
    }
  }
}
