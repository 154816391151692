@import './variables.scss';
@import './scut.scss';

/*
  TEXT MIXIN
  If the $size / $lineHeight / $weight arguments are not empty,
  then output the argument.
*/

@mixin text($size: null, $lineHeight: null, $weight: null, $letter_spacing: null) {
  @if $size != null {
    font-size: $size;
  }

  @if $lineHeight != null {
    line-height: $lineHeight;
  }

  @if $weight != null {
    font-weight: $weight;
  }

  @if $letter_spacing != null {
    letter-spacing: $letter_spacing;
  }
}

/*
  MEDIA QUERIES MIXIN
*/

@mixin min-width($threshold) {
  @media screen and (min-width: scut-rem($threshold)) {
    @content;
  }
}

// small devices
@mixin small($threshold: $screen-small-min) {
  @media screen and (min-width: $threshold) {
    @content;
  }
}

// medium devices
@mixin medium($threshold: $screen-medium-min) {
  @media screen and (min-width: $threshold) {
    @content;
  }
}

// large devices
@mixin large($threshold: $screen-large-min) {
  @media screen and (min-width: $threshold) {
    @content;
  }
}

// extra large devices
@mixin xlarge($threshold: $screen-xlarge-min) {
  @media screen and (min-width: $threshold) {
    @content;
  }
}

/*
  MISCELLANEOUS  MIXINS
*/

@mixin box-shadow {
  box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.3);
}

@mixin button-style($bg-color: $primary-color, $color: $white, $full-color: $white) {
  border: 3px solid $bg-color;
  color: $color;

  &:active {
    background-color: $bg-color;
  }

  &.Button--full {
    color: $full-color;
    background-color: $bg-color;

    &:active {
      background-color: lighten($bg-color, 10%);
    }
  }
}
