.workoutSavedPopup__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $space-2;

  & > .Button {
    width: auto;
  }
}
