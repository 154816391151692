@import './../../styles/mixins.scss';

.statsRoundActive {
  height: 20%;
  width: 100%;
  display: flex;
  padding-top: 1rem;

  &__box {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;

    :first-child {
      padding-right: 1rem;
    }

    :last-child {
      padding-left: 1rem;
    }
  }

  &__singleStat {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
  }

  &__border {
    border-left: 4px solid #001f19;
  }

  .key {
    display: inline-block;
    margin: 0;
    padding: 0;
    color: $gray-light;
  }

  .value {
    display: inline-block;
    text-align: center;
    color: white;
  }

  .value + .key {
    border-left: 4px solid $black;
    padding-left: 1rem;
  }

  &__total {
    flex: 0 0 50%;
    background-color: $gray-dark;
    border-radius: 2rem;
    margin-right: 10px;
    padding: 1rem 2rem;
  }

  &__last {
    margin-left: 10px;
    flex: 0 0 calc(50% - 20px);
    background-color: $gray-dark;
    border-radius: 2rem;
    padding: 1rem 2rem;
  }
}
