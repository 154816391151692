@import './../../styles/mixins.scss';

.NRVideo {
  &__playerWrapper {
    position: relative;
    border-radius: 5px;
    padding-top: 56.25%;

    &--hide {
      display: none;
    }
  }

  &__reactPlayer {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px;
    overflow: hidden;
    max-height: 100%;
    max-width: 100%;

    &--hide {
      display: none;
    }
  }

  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__playIcon {
    &--wrapper {
      width: 100%;
      height: 100%;
      display: grid;
      place-items: center;
    }

    &--circle {
      width: 5rem;
      height: 5rem;
      display: grid;
      place-items: center;
      border: 3px solid white;
      border-radius: 50%;
    }
  }
}
