@import '../../styles/variables';
@import '../../styles/mixins';

.Button {
  padding: 0.5em 2em;
  border-radius: 1em;
  font-weight: bold;
  background-color: transparent;
  color: $white;
  width: 20rem;
  height: 4rem;
  font-family: 'CanaroBold';

  &:hover {
    cursor: pointer;
  }

  &--small {
    min-height: 32px;
    padding: 0.3rem 2rem;
    min-width: 8rem;
  }

  &--primary {
    @include button-style($primary-color);
  }

  &--secondary {
    @include button-style($secondary-color);
  }

  &--tertiary {
    @include button-style($tertiary-color, $white, $black);
  }

  &--disabled {
    cursor: not-allowed !important;
    border: 3px solid $gray-light !important;
    background-color: $gray-light !important;
    color: $black !important;
  }

  &--light {
    border: 2px solid $gray-light;
    &:active {
      background-color: $gray-light;
    }
  }

  &--text {
    width: fit-content;
    height: fit-content;
    border: none;
    border-radius: 0;
    padding: 0;
  }

  &--square {
    min-width: 45px;
    min-height: 45px;
    padding: 0.5rem 0;
    display: grid;
    place-items: center;
  }

  &.icon {
    display: grid;
    place-items: center;
  }
}
