.input {
  border: none;
  outline: none;
  color: $white;
  background-color: transparent;
  font-family: 'CanaroBook';
  font-size: 24px;
  border-bottom: 2px solid $gray-light;
  padding: 5px 10px;

  &::placeholder {
    font-family: 'CanaroBook';
    font-size: 24px;
  }

  &:focus {
    outline: none;
  }

  &[data-filled='true'] {
    color: white !important;
  }

  &[data-filled='false'] {
    color: $gray-light !important;
  }
}

c input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border-bottom: 2px solid white !important;
  -webkit-text-fill-color: white !important;
  -webkit-box-shadow: 0 0 0px 100px #182222 inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
