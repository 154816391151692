$group-workout-color: #fde2e2;

.grid-container-home {
  height: 100%;
  display: grid;
  grid-gap: 2rem;
  padding: 2rem;
  text-align: center;
  grid-template-areas:
    'one two three'
    'one two three'
    'one four three'
    'five four six'
    'five four seven';

  .grid-item {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-family: 'CanaroBold';
    border-radius: 0.5rem;
    background-color: black;
  }

  .grid-item.one {
    grid-area: one;
  }

  .grid-item.two {
    grid-area: two;
    &:hover {
      background-color: $group-workout-color;
      color: black;
    }
  }

  .grid-item.three {
    grid-area: three;
  }

  .grid-item.four {
    grid-area: four;
  }

  .grid-item.five {
    grid-area: five;
  }

  .grid-item.six {
    grid-area: six;
  }

  .grid-item.seven {
    grid-area: seven;
  }
}

.not-clickable {
  cursor: not-allowed;
}
