@import '../../styles/mixins.scss';

.workoutFeedback {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 2rem;
  background-color: $gray-dark;
  padding: 1.5rem $space-1;

  & > h3 {
    margin-bottom: $space-1;
    margin-inline: auto;

    & > span:first-child {
      margin-right: $space-half;
    }

    & > span:last-child {
      color: $tertiary-color;
    }
  }

  & .Button {
    width: 100%;
    height: auto;
  }

  &__unavailable {
    margin-top: auto;
  }
}
