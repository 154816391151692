.checkBox {
  display: flex;

  & > * {
    flex-grow: 0;
  }

  & > input {
    margin: 0;

    &[type='checkbox'] {
      -webkit-appearance: none;
      appearance: none;
      background-color: var(--form-background);
      font: inherit;
      color: currentColor;
      transform: translateY(-0.075em);
      display: grid;
      place-content: center;
      margin: 0;
      min-width: 1.3rem;
      min-height: 1.3rem;
      border: 0.15em solid $primary-color;
      border-radius: 0.15rem;
    }

    &[type='checkbox']::before {
      content: '';
      width: 0.65em;
      height: 0.65em;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      transform: scale(0);
      transform-origin: bottom left;
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--form-control-color);
      background-color: $primary-color;
    }

    &[type='checkbox']:checked::before {
      transform: scale(1);
    }
  }

  & > p {
    margin: 0 0 0 $space-1;

    &[data-checked='true'] {
      color: white;
    }

    &[data-checked='false'] {
      color: $gray-light;
    }
  }
}
