@import './../../styles/variables';

.tabsPanel {
  &__wrapper {
    display: flex;
    width: 100%;
    text-align: center;
  }

  &__tab {
    flex: 1;
    padding: 0.5rem 0;
    border-bottom: 3px solid $primary-color;
    background-color: rgba($primary-color, 0.5); // Use rgba to add transparency to the background

    &--active {
      background-color: $primary-color;
    }
  }

  &__tab:first-of-type {
    border-radius: 16px 0px 0px 0px;
  }

  &__tab:last-of-type {
    border-radius: 0px 16px 0px 0px;
  }
}
