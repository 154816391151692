.round_exercise_box {
  display: flex;
  flex-direction: column;
  width: 100%;

  & > .Box {
    width: 60%;
  }

  &__title {
    flex: 1;

    > h2 {
      margin-bottom: 2rem;
    }

    .icon {
      float: right;
    }

    &__wrapper {
      color: $gray-light;
      display: flex;

      > span {
        font-family: 'CanaroBook';
      }
    }

    &__time {
      flex: 1;
      border-left: 6px solid $primary-color;
      padding-left: 0.5rem;
      margin-left: 0.5rem;
    }
  }
}

.round-complete-overview {
  position: relative;
  margin-top: 1rem;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 100%;
    height: 600px;

    @include medium {
      flex-direction: row;
      justify-content: space-between;
      height: 100%;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-top: 2rem;
    }
  }

  .card {
    width: calc(50% - 2ch);
    min-width: 20ch;
    max-width: 70ch;
    margin: auto;
    padding: 2ch;
    background-color: $gray-dark;
    border-radius: 25px;
    text-align: center;
    width: 900px;

    .subtitle {
      color: $gray-light;
      font-size: 2rem;
      margin: 4rem 0 1rem;
    }
  }
}

.round_exercise_counter {
  position: relative;
  display: flex;
  justify-content: center;

  @include medium {
    align-items: center;
    justify-content: flex-end;
  }

  > .chart {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.round_exercise_video {
  max-width: 470px !important;
  max-height: 395px;
  object-fit: contain;
  object-position: left;
  width: 100%;

  @include medium {
    max-width: 750px !important;
    max-height: 425px;
  }

  @include large {
    max-width: 870px !important;
    max-height: 475px;
  }

  @include xlarge {
    max-width: 1040px !important;
    max-height: 525px;
  }
}

.bars {
  display: flex;
  width: 90%;
  margin: 3rem auto;

  &__bar {
    flex: 1;
    height: 36px;
    margin: 3px;
    border-radius: 10px;
    background-color: $primary-dark;

    &--fill {
      background-color: $primary-color;
    }
  }
}
