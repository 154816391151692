@import './../../styles/mixins.scss';

.roundsGraph {
  padding-top: 1rem;
  height: 100%;

  &__wrapper {
    width: calc(100% - 0.5rem);
    padding-top: 1rem;
    padding-bottom: 1rem;
    bottom: 0;
    display: flex;
    position: relative;
    height: 90%;
  }

  &__graphContainer {
    overflow-x: scroll;
    scroll-behavior: smooth;
    display: flex;
    position: relative;
    width: 90%;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__weightValue {
    position: absolute;
    bottom: 3rem;
    left: 1rem;
  }

  &__singleRoundWrapper {
    position: relative;
    height: 100%;
  }

  &__roundSeperator {
    height: 95%;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 1.1rem;

    @include large {
      height: 100%;
    }

    &__verticalLine {
      position: relative;
      border-left: 2px solid white;
      height: calc(100% - 1.2rem);
      z-index: 1;
      width: 2px;

      > p {
        position: absolute;
        left: 0.5rem;
      }
    }
  }

  &__arrowRight {
    position: sticky;
    right: 0;
    top: 0;
    width: 40px;
    height: calc(100% - 2rem);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $gray-dark;
    cursor: pointer;
    z-index: 1;

    &:hover {
      background-color: darken($primary-color, 20%);
    }

    &::after {
      content: '>';
      font-size: 2rem;
      flex: 0;
      width: 40px;
    }
  }

  &__y-axis {
    &__wrapper {
      position: sticky;
      margin-right: 1.5rem;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: 1rem;

      &--roundComplete {
        height: calc(100% - 1.5rem);
      }
    }

    &__tick {
      display: flex;
      position: relative;
      margin: 0;
      height: 1rem;

      > p {
        margin: 0;
      }
    }

    &__line {
      border-color: $black-light;
      position: absolute;
      left: 2rem;
      z-index: 5;
      width: 37rem;
      height: 2px;
      z-index: 1;
      &--roundComplete {
        width: 39rem;
      }

      @include medium {
        width: 40rem;
        &--roundComplete {
          width: 41.5rem;
        }
      }

      @include large {
        width: 52rem;
        &--roundComplete {
          width: 53.25rem;
        }
      }

      @include xlarge {
        width: 58rem;
        &--roundComplete {
          width: 59.5rem;
        }
      }
    }

    &__avg {
      display: flex;
      align-items: center;
      position: absolute;
      font-family: 'CanaroBold';
      left: 2.5rem;

      & > div {
        position: relative;

        & > p {
          position: absolute;
          height: fit-content;
          right: 0.5rem;
          top: 50%;
          transform: translateY(-50%);
          margin: 0;
        }
      }
    }

    &__avgLine {
      margin-block-start: 0;
      margin-block-end: 0;
      border-color: white;
      position: absolute;
      z-index: 5;
      width: 37rem;
      height: 2px;
      z-index: 1000;

      &--roundComplete {
        width: 39rem;
      }

      @include medium {
        width: 40rem;

        &--roundComplete {
          width: 41.5rem;
        }
      }

      @include large {
        width: 52rem;

        &--roundComplete {
          width: 53.25rem;
        }
      }

      @include xlarge {
        width: 58rem;
        left: 2rem;

        &--roundComplete {
          width: 59.5rem;
        }
      }
    }
  }

  &__x-axis {
    &__wrapper {
      position: absolute;
      width: 103%;
      bottom: 0;
      left: 0;

      @include medium {
        bottom: -0.25rem;
      }

      @include large {
        bottom: -0.75rem;
      }

      @include xlarge {
        bottom: -1.1rem;
      }

      &--roundComplete {
        width: 92%;
        left: 2.5rem;
      }
    }

    &__line {
      margin: 0;
      border-color: white;
      width: 100%;
      height: 2px;
    }

    &__ticksWrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}
