.weightButtons {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
}

.singleWeightButton {
  border: 3px solid $white;
  width: 12rem;

  &:hover,
  &--active {
    background-color: $white;
    color: $black;
  }
}
