@import '../../styles/variables';

.ChartEx {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  &__roundtext {
    height: 100%;
    text-align: center;
    max-width: 2px;
  }

  &__roundsbars {
    display: flex;
    justify-content: flex-end;
  }

  &.history {
    .Chart__bars {
      justify-content: flex-end;
    }
  }

  &__wrapper {
    display: flex;
    height: 100%;
  }

  &__xaxis {
    .chart-axis {
      display: flex;
      flex-direction: row;
      float: right;
      width: calc(100% - 3rem);
      justify-content: space-between;

      & > div {
        position: relative;
        font-size: 9pt;

        &:after {
          top: -15px;
          content: '';
          display: block;
          position: absolute;
          left: 50%;
          width: 1px;
          height: 10px;
          background-color: white;
        }

        &:first-child {
          &:after {
            left: 0;
          }
        }

        &:last-child {
          &:after {
            left: 100%;
          }
        }
      }
    }
  }
  &__yaxis {
    display: flex;
    width: 50px;
    flex-direction: column;
    position: relative;

    &__average {
      position: absolute;
      left: -50px;
      padding-top: 10px;
      font-weight: bold;
    }

    &__line {
      background-color: $gray-dark;
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 2px;

      &--1 {
        top: 0%;
      }

      &--2 {
        top: 16.6%;
      }
      &--3 {
        top: 33.3%;
      }

      &--4 {
        top: 50%;
      }

      &--5 {
        top: 66.66%;
      }

      &--6 {
        top: 83.3%;
      }
    }
  }

  &__scrollable {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &__roundssec {
    flex-basis: 12px;
    margin-left: 1px;
    margin-right: 1px;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
  }

  &__bars {
    width: calc(100% - 3rem);
    height: calc(100% - 2rem);
    border-bottom: 1px solid white;
    display: flex;

    &__bar {
      background-color: $primary-color;
      display: block;
      width: 12px;
      border-radius: 15px 15px 0 0;
      align-self: flex-end;
      margin: 0 0.1rem;
      transition: max-height 500ms linear;
      max-height: 100%;

      &.secondary {
        background-color: $player2;
      }
    }
  }

  &__average {
    position: absolute;
    left: 0;
    height: 2px;
    background-color: white;
    width: calc(100%);
  }
}
