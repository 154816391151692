.PopupPause {
  text-align: center;

  > h2 {
    margin-bottom: 2rem;
  }

  > h3 {
    margin: 3rem;
  }

  > button {
    margin-top: 2rem;
  }
}
