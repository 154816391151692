@import '../../styles/variables';

.loading {
  width: 100%;
  display: block;
  text-align: center;
}

.workouts {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  > h3 {
    margin: 1rem 0;
  }

  &__scroll {
    width: 100%;
    display: flex;
  }

  &__playerselect {
    width: 100%;
    height: 200px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;

    &__players {
      margin-top: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;

      & > Button {
        margin: 0 1rem;
      }
    }

    &__level {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 32px;
      font-family: 'CanaroBook';
      border-top: 4px solid #001f19;
      margin-top: 2rem;
      padding-top: 2rem;
      width: 1130px;
      margin: 2rem auto 0;

      & > div {
        margin: 0 1rem;
        position: relative;
        border-bottom: 4px solid transparent;

        &.selected {
          border-bottom: 4px solid $primary-color;
          font-family: 'CanaroBold';
        }

        &:hover {
          cursor: pointer;
        }

        &:after {
          position: absolute;
          content: '|';
          right: -1.25rem;
          display: inline-block;
          font-size: 32px;
          font-family: 'CanaroBold';
          color: $primary-color;
          border-bottom: 0;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  &__config {
    width: 100%;
    min-height: 350px;
    border-radius: 2rem;
    background-color: $primary-dark;
    display: flex;

    &__imgWrapper {
      overflow: hidden;
      flex: 1;
      border-radius: 2rem;
    }

    &__img {
      background-image: url('../../assets/images/freestyle-setup.jpg');
      height: 100%;
      margin: 0 -4rem 0 0;
      background-size: cover;
    }

    &__info {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 1.5rem 2rem;
      max-width: 55%;

      h2 {
        & > span {
          font-family: 'CanaroBook';
        }
      }

      .training {
        margin-top: 4rem;
        margin-bottom: 2rem;
      }

      .subtitle {
        margin-top: 1rem;
      }

      .duration {
        margin-top: 0.5rem;

        .icon {
          margin-right: 0.5rem;
        }

        label {
          position: relative;
          bottom: 5px;
        }
      }

      Button {
        align-self: flex-end;
        margin-top: auto;
        max-width: 382px;
        width: 100% !important;
      }
    }
  }
}
