@import '../../../styles/mixins.scss';

.popupWrapper {
  --width: 721.5px;
  @include medium {
    --width: 768px;
  }
  @include large {
    --width: 960px;
  }
  padding: $space-2 $space-2 $space-3 $space-2;
  background-color: $black;
  width: var(--width);
  @include box-shadow;
  border-radius: 32px;
  overflow: hidden;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: $space-1;

    &__logo {
      width: 3.5rem;
      background-image: url('../../../assets/images/logo_small.svg');
      background-size: contain;
      background-repeat: no-repeat;
    }

    & > .icon {
      cursor: pointer;
    }
  }

  &__body {
    margin-inline: $space-3;
  }
}

.popupBackground {
  z-index: 200;
  position: fixed;
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba($black, 0.8);
}
