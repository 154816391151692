@import '../../styles/variables';

.statsRoundComplete {
  width: 100%;
  background-color: $gray-dark;
  border-radius: 25px;
  padding: 0rem 1rem;

  &__row {
    padding: 0.7rem 1rem;
    border-bottom: 0.25rem solid $black;

    &:last-child {
      border: none;
    }

    &--title {
      color: $gray-light;
      display: block;
    }

    &--value {
      color: white;
      display: block;
    }
  }
}
