.Round {
  height: 100%;
  position: relative;

  &__head {
    padding-bottom: 1rem;
    height: 10%;

    &__current-round {
      display: flex;
      flex-direction: column;
      font-weight: bold;

      &__round {
        flex: 0;
      }

      &__active-player {
        flex: 0;

        span {
          font-family: 'CanaroBold';
          border-bottom: 2px solid $primary-color;
        }

        &.player1 {
          color: $player1;
        }

        &.player2 {
          color: $player2;
        }
      }
    }

    &__time-left {
      display: flex;
      align-items: center;
      justify-content: space-around;

      & .player1 {
        color: $player1;
      }

      & .player2 {
        color: $player2;
      }
    }
  }

  &__content {
    position: relative;

    > .chart {
      position: absolute;
      right: 0;
      top: 150px;
      @include medium {
        top: 375px;
      }
      @include large {
        top: 400px;
      }
      @include xlarge {
        top: 550px;
      }
    }

    &__instructions {
      display: flex;
      justify-content: space-between;

      justify-content: space-around;
      height: 600px;

      @include medium {
        height: 100%;
        flex-direction: row;
        justify-content: space-between;
      }

      &__media {
        object-fit: contain;
        object-position: left;
        max-width: 470px !important;
        max-height: 280px;
        width: 100%;

        @include medium {
          max-width: 750px !important;
          max-height: 400px;
        }

        @include large {
          max-width: 800px !important;
          max-height: 435px;
        }

        @include xlarge {
          max-width: 1050px !important;
          max-height: 480px;
        }
      }
    }

    &__exercises-container {
      display: flex;
      flex-direction: column;

      @include medium {
        height: 25rem;
      }

      @include large {
        height: 27rem;
      }

      @include xlarge {
        height: 36rem;
      }
    }

    &__next-round-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;

      @include medium {
        margin-top: 0px;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }

    &__next-round-box {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
    }

    &__next-round-text {
      display: flex;
      justify-content: flex-end;
      margin: 1.5rem 0;

      @include xlarge {
        margin-bottom: 0;
        margin-right: 1.5rem;
      }
    }

    &__next {
      margin-top: 2rem;
      margin-right: 5rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      &__title {
        flex: 0 0 9rem;
        font-size: 32px;
        font-family: 'CanaroBold';
      }

      .timer {
        flex: 0 0 10rem;
      }
    }
  }
}
