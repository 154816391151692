@import './../../../styles/mixins.scss';

.PopupIdleLogout {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > h3 {
    margin: 3rem 0 1rem;
  }

  .Button {
    margin: 0 2rem;
  }

  &__buttonBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.5rem;

    @include medium {
      flex-direction: row;
    }
  }
}
