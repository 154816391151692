.icon {
  display: inline-block;
  text-align: center;
  display: inline-flex;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    align-self: center;
    fill: currentColor;
  }

  & + & {
    margin: 0 0.2rem;
  }
}
