// BREAKPOINTS
$screen-small-min: 962px;
$screen-medium-min: 1024px;
$screen-large-min: 1280px;
$screen-xlarge-min: 1440px;

// SPACE
$space-half: 0.5rem;
$space-1: 1rem;
$space-2: 2rem;
$space-3: 3rem;
$space-4: 4rem;
$space-5: 5rem;
$space-6: 6rem;

// COLOURS
$primary-color: #00c0ba;
$primary-light: #99e6e3;
$primary-dark: #006663;

$secondary-color: #fb7558;
$secondary-light: #fcac9a;

$tertiary-color: #ffbd2c;
$tertiary-light: #ffdb9a;

$black: #182222;
$black-light: #464e4e;
$white: #ffffff;

$gray-dark: #282e2e;
$gray-light: #b5b5b5;

// this is currently the only gradient, but it is named tertiary to align with the color scheme
$gradiant-tertiary: linear-gradient(210deg, transparentize($tertiary-color, 0.7) 7.59%, rgba(255, 189, 44, 0) 85%);

$player1: $primary-color;
$player1Inactive: rgba(0, 192, 186, 0.4);
$player2: $tertiary-color;
$player2Inactive: rgba(255, 189, 44, 0.4);
