.hitMeBtn {
  position: absolute;
  bottom: 6rem;
  right: 1rem;
  z-index: 200;
  display: grid;
  place-items: center;
}

.customColor {
  color: black;
}
