.popupCheckEmail {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h2.light {
    margin-bottom: $space-2;
  }

  & > h4 {
    margin-bottom: $space-2;
  }
}
