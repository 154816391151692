@import '../../styles/variables.scss';

@keyframes slideInFromTop {
  0% {
    transform: translate(-50%, -100%);
  }
  100% {
    transform: translate(-50%, 0);
  }
}

@keyframes slideOutToTop {
  0% {
    transform: translate(-50%, 0);
  }
  100% {
    transform: translate(-50%, -100%);
  }
}

.snackbarWrapper {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  align-items: center;
  gap: $space-half;
  padding: 0.75rem 2rem;
  border-radius: 0rem 0rem 2rem 2rem;
  z-index: 999;
  white-space: nowrap;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: slideInFromTop;

  &[data-status='success'] {
    background-color: $primary-color;
  }

  &[data-status='failed'] {
    background-color: $secondary-color;
  }
}

.snackbarWrapperSlideOut {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: slideOutToTop;
}

.snackbarBtn {
  font-family: 'CanaroBold';
  color: $white;
  background-color: inherit;
  border-radius: 1rem;
  padding: 0.5rem;
  border: 2px solid $white;
}
