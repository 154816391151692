@import './../../../styles/mixins.scss';

.PopupSaveResultsLoggedIn {
  text-align: center;

  > h3 {
    margin: 5rem 0 3rem;
  }

  .Button {
    margin: 0 2rem;
  }

  &__buttonBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    @include medium {
      flex-direction: row;
    }
  }
}
