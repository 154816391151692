@import '../../styles/mixins.scss';

.workoutOverview {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__scroll {
    position: relative;
    width: 100%;
  }

  &__intro {
    & > p {
      width: 676px;
      font-family: 'CanaroBook';
    }
  }

  &__footer {
    padding: 0.5rem 2rem;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: scut-rem(80);
    background-color: $gray-dark;
    font-family: 'CanaroBook';

    & > h4 {
      margin-right: 0.5rem;
    }

    @include xlarge {
      height: scut-rem(160);
    }

    span:first-child {
      font-family: 'CanaroBook';
      margin-left: 0;
    }

    span {
      padding: 0.5rem 0rem;
      margin: 0.5rem 1.5rem;
      position: relative;

      &.small {
        margin: 0.5rem;
      }

      &.border-right {
        &:after {
          top: scut-rem(8);
          position: absolute;
          right: -1.8rem;
          content: '';
          display: inline-block;
          height: scut-rem(30);
          width: scut-rem(5);
          background-color: $primary-color;

          @include medium {
            top: scut-rem(10);
          }

          @include large {
            top: scut-rem(15);
          }
        }
      }

      &.noclick {
        &:hover {
          cursor: auto;
          border-bottom: 0;
        }
      }

      &:hover {
        cursor: pointer;
        border-bottom: 4px solid $primary-color;
      }

      &.selected {
        cursor: pointer;
        border-bottom: 4px solid $primary-color;
        font-family: 'CanaroBold';
      }
    }
  }

  &__workout-exercises {
    display: flex;
    margin-top: 2rem;
  }

  &__workout-info {
    h3 {
      margin: 1rem 0 0.3rem;
    }
  }

  &__workout-details {
    display: flex;
    padding-top: 0.5rem;

    & > span {
      @include text(scut-rem(26), 24px, null, null);
      margin-left: 1rem;
      padding-left: 1rem;
      border-left: 2px solid $primary-color;

      &:nth-child(2) {
        border-left: none;
        margin-left: 0;
      }
    }
  }

  &__back {
    cursor: pointer;
    a {
      font-family: 'CanaroBold';
      text-decoration: none;
      color: white;
    }
  }

  &__media {
    object-fit: contain;
    object-position: left;
    max-width: scut-rem(470) !important;
    height: 250px;

    @include medium {
      max-width: scut-rem(690) !important;
      height: 300px;
    }

    @include large {
      max-width: scut-rem(670) !important;
      height: 350px;
    }

    @include xlarge {
      max-width: scut-rem(770) !important;
      height: 375px;
    }

    &--loading {
      display: grid;
      place-items: center;
    }
  }
}

.start-workout-Btn {
  margin-left: auto;
  width: fit-content;

  > .Button {
    width: scut-rem(240) !important;

    @include medium {
      width: scut-rem(256) !important;
    }

    @include large {
      width: scut-rem(320) !important;
    }
  }
}
