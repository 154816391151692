.workout-new {
    text-align: center;
    background-color: #000;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.8' numOctaves='4' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' opacity='0.4' fill='black' filter='url(%23noise)'/%3E%3C/svg%3E");
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .round-info {
    color:black;
  }

  .hidden-audio {
    display: none;
  }

 .intensity-wrapper {
  color: black;
 }

 .group-hit-challenge-wrapper {
  color: black;
 }

 .group-group-challenge-wrapper {
  color: black;
 }
